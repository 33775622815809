import {Bento, Box, Grid, Heading, Link, River, RiverBreakout, SectionIntro, Text, Timeline} from '@primer/react-brand'

import {DeviceMobileIcon} from '@primer/octicons-react'
import {analyticsEvent} from '../../../../lib/analytics'
import {Image} from '../../../../components/Image/Image'
import AutoPlayVideo from './AutoPlayVideo'

import FeaturesBreakoutLgVideo from '../_assets/features-breakout.mp4'
import FeaturesBreakoutLgPoster from '../_assets/features-breakout-poster.webp'
import FeaturesBreakoutSmVideo from '../_assets/features-breakout-sm.mp4'
import FeaturesBreakoutSmPoster from '../_assets/features-breakout-poster-sm.webp'

import CopilotExtensionsImage from '../_assets/copilot-extensions.webp'
import CopilotMultiFileImage from '../_assets/copilot-multifile.webp'
import CopilotMobileImage from '../_assets/copilot-mobile.webp'
import CopilotModelsImage from '../_assets/copilot-models-poster.webp'
import CopilotModelsVideo from '../_assets/copilot-models.mp4'
import CopilotReviewImage from '../_assets/copilot-review.webp'
import CopilotTerminalLgImage from '../_assets/copilot-terminal.webp'
import CopilotTerminalSmImage from '../_assets/copilot-terminal-sm.webp'
import CopilotTerminalBgImage from '../_assets/copilot-terminal-bg.webp'
import FeaturesRiver1LgVideo from '../_assets/features-river-1.mp4'
import FeaturesRiver1SmVideo from '../_assets/features-river-1-sm.mp4'
import FeaturesRiver1LgPoster from '../_assets/features-river-1-poster.webp'
import FeaturesRiver1SmPoster from '../_assets/features-river-1-poster-sm.webp'

import AppStoreImage from '../_assets/app-store.png'
import GooglePlayImage from '../_assets/google-play.png'

export default function FeaturesSection() {
  return (
    <section
      id="features"
      className="lp-Section lp-Section--compact lp-SectionIntro--regular"
      style={{
        position: 'relative',
        zIndex: 1,
        background: 'rgb(13, 17, 23) linear-gradient(180deg, #161B22 0%, #0D1117 25%)',
        paddingTop: 'var(--base-size-96)',
      }}
    >
      <Grid className="lp-Section-container--centerUntilMedium lp-Grid--noRowGap">
        <Grid.Column span={12}>
          <SectionIntro fullWidth align="center" className="lp-SectionIntro">
            <SectionIntro.Label size="large" className="lp-Label--section">
              Features
            </SectionIntro.Label>
            <SectionIntro.Heading size="2" weight="semibold">
              Customizable. Contextual. <br className="break-whenWide" /> AI-powerful.
            </SectionIntro.Heading>
          </SectionIntro>
        </Grid.Column>

        <Grid.Column span={12}>
          <RiverBreakout className="lp-RiverBreakout">
            <RiverBreakout.Visual className="lp-River-visual">
              <AutoPlayVideo
                src={FeaturesBreakoutLgVideo}
                poster={FeaturesBreakoutLgPoster}
                width="1248"
                height="647"
                className="d-none d-md-block lp-RiverBreakout-video--lg"
                aria-label="Video demonstrating how GitHub Copilot accelerates workflow through interactive codebase chat"
                darkButton
                analyticsProps={{
                  context: 'demo_gif',
                  location: 'river_breakout',
                }}
              />
              <AutoPlayVideo
                src={FeaturesBreakoutSmVideo}
                poster={FeaturesBreakoutSmPoster}
                width="350"
                height="380"
                className="d-block d-md-none lp-RiverBreakout-video--sm"
                aria-label="Video demonstrating how GitHub Copilot accelerates workflow through interactive codebase chat"
                darkButton
                analyticsProps={{
                  context: 'demo_gif',
                  location: 'river_breakout',
                }}
              />
            </RiverBreakout.Visual>
            <RiverBreakout.Content
              trailingComponent={() => (
                <Timeline className="lp-Timeline">
                  <Timeline.Item>
                    <em>Answers that know how you code.</em> GitHub Copilot can use your code and Custom Instructions to
                    code the way you prefer.
                  </Timeline.Item>
                  <Timeline.Item>
                    <em>Create tests, docs, and more.</em> Ask GitHub Copilot to generate them and add them to your
                    codebase with just one click.
                  </Timeline.Item>
                </Timeline>
              )}
            >
              <Text className="lp-RiverBreakout-text">
                <em>Create tests, docs, and more.</em> Ask GitHub Copilot a question, get the right answer for you, and
                accept the code with a single click.
                <span style={{marginTop: '32px', marginBottom: '48px', display: 'block'}}>
                  <Link
                    href="https://docs.ghproxy.lvwenzhuo2003.xyz/en/copilot/example-prompts-for-github-copilot-chat"
                    variant="accent"
                    {...analyticsEvent({
                      action: 'chat_docs',
                      tag: 'link',
                      context: 'copilot_chat_example_prompts',
                      location: 'features',
                    })}
                  >
                    Our favorite Copilot prompts
                  </Link>
                </span>
              </Text>
            </RiverBreakout.Content>
          </RiverBreakout>

          <River imageTextRatio="50:50" className="lp-River-mod">
            <River.Visual className="lp-River-visual">
              <AutoPlayVideo
                src={CopilotModelsVideo}
                poster={CopilotModelsImage}
                width="708"
                height="472"
                aria-label="GitHub Copilot model choices"
                darkButton
                analyticsProps={{
                  context: 'demo_gif',
                  location: 'feature_river_models',
                }}
              />
            </River.Visual>
            <River.Content>
              <Heading as="h3" size="5">
                Choose your model
              </Heading>
              <Text as="p" variant="muted" className="lp-River-text">
                Use models like Anthropic&apos;s{' '}
                <a
                  className="lp-Link--inline"
                  href="https://docs.ghproxy.lvwenzhuo2003.xyz/en/copilot/using-github-copilot/using-claude-sonnet-in-github-copilot"
                  {...analyticsEvent({
                    action: 'docs_instructions',
                    tag: 'inline_link',
                    context: 'using_sonnet',
                    location: 'features',
                  })}
                >
                  Claude 3.5 Sonnet
                </a>
                , OpenAI o1, and GPT 4o to excel at coding tasks one minute — and deeper reasoning and understanding the
                next.
              </Text>
            </River.Content>
          </River>

          <River imageTextRatio="50:50" className="lp-River-mod">
            <River.Visual className="lp-River-visual">
              <Image
                src={CopilotMultiFileImage}
                alt="GitHub Copilot including multiple files in a working set"
                width="708"
                height="472"
              />
            </River.Visual>
            <River.Content>
              <Heading as="h3" size="5">
                Multi-file edits
              </Heading>
              <Text as="p" variant="muted" className="lp-River-text">
                Tell GitHub Copilot what to do, and watch it update multiple files right in your editor.
              </Text>
            </River.Content>
          </River>

          <River imageTextRatio="50:50" className="lp-River-mod">
            <River.Visual className="lp-River-visual">
              <AutoPlayVideo
                src={FeaturesRiver1LgVideo}
                poster={FeaturesRiver1LgPoster}
                width="708"
                height="472"
                darkButton
                className="d-none d-md-block hide-reduced-motion"
                aria-label="Showing editor with GitHub Copilot code suggestions in real time"
              />
              <AutoPlayVideo
                src={FeaturesRiver1SmVideo}
                poster={FeaturesRiver1SmPoster}
                width="350"
                height="290"
                darkButton
                className="d-block d-md-none hide-reduced-motion"
                aria-label="Showing editor with GitHub Copilot code suggestions in real time"
              />
            </River.Visual>
            <River.Content>
              <Heading as="h3" size="5">
                Get AI-based suggestions in real time
              </Heading>
              <Text as="p" variant="muted" className="lp-River-text">
                GitHub Copilot suggests code completions as you type and turns natural language prompts into coding
                suggestions based on your project&apos;s conventions.
              </Text>
              <Link
                href="https://docs.ghproxy.lvwenzhuo2003.xyz/en/copilot/using-github-copilot/asking-github-copilot-questions-in-your-ide#slash-commands"
                variant="accent"
                {...analyticsEvent({
                  action: 'docs_instructions',
                  tag: 'link',
                  context: 'slash_commands',
                  location: 'features',
                })}
              >
                Use slash commands
              </Link>
            </River.Content>
          </River>

          <River imageTextRatio="50:50" className="lp-River-mod">
            <River.Visual className="lp-River-visual">
              <a
                href="https://ghproxy.lvwenzhuo2003.xyz/features/preview/copilot-code-review"
                tabIndex={-1}
                {...analyticsEvent({
                  action: 'docs_instructions',
                  tag: 'image',
                  context: 'copilot_review',
                  location: 'features',
                })}
              >
                <Image
                  src={CopilotReviewImage}
                  alt="GitHub Copilot performing a code review"
                  width="708"
                  height="472"
                />
              </a>
            </River.Visual>
            <River.Content>
              <Heading as="h3" size="5">
                Instant feedback
              </Heading>
              <Text as="p" variant="muted" className="lp-River-text">
                Ask GitHub Copilot to review your work, uncover hidden bugs, fix mistakes, and more—before you get a
                human review.
              </Text>
              <Link
                href="https://ghproxy.lvwenzhuo2003.xyz/features/preview/copilot-code-review"
                variant="accent"
                {...analyticsEvent({
                  action: 'docs_instructions',
                  tag: 'link',
                  context: 'copilot_review',
                  location: 'features',
                })}
              >
                Get early access
              </Link>
            </River.Content>
          </River>

          <River imageTextRatio="50:50" className="lp-River-mod">
            <River.Visual className="lp-River-visual">
              <a
                href="https://ghproxy.lvwenzhuo2003.xyz/features/copilot/extensions"
                tabIndex={-1}
                {...analyticsEvent({
                  action: 'docs_instructions',
                  tag: 'image',
                  context: 'copilot_extensions',
                  location: 'features',
                })}
              >
                <Image
                  src={CopilotExtensionsImage}
                  alt="GitHub Copilot Extensions for Azure, Datastax, Docker, MongoDB and Sentry"
                  width="708"
                  height="472"
                />
              </a>
            </River.Visual>
            <River.Content>
              <Heading as="h3" size="5">
                Extensions for your favorite tools
              </Heading>
              <Text as="p" variant="muted" className="lp-River-text">
                Check logs, create feature flags, and deploy apps—all from Copilot Chat, enhanced by an ecosystem of
                extensions from third party tools and services.
              </Text>
              <Link
                href="https://ghproxy.lvwenzhuo2003.xyz/features/copilot/extensions"
                variant="accent"
                {...analyticsEvent({
                  action: 'docs_instructions',
                  tag: 'link',
                  context: 'copilot_extensions',
                  location: 'features',
                })}
              >
                Explore GitHub Copilot Extensions
              </Link>
            </River.Content>
          </River>
        </Grid.Column>

        {/* Spacer */}
        <Box paddingBlockStart={48} aria-hidden />

        <Grid.Column span={12}>
          <Bento className="Bento Bento--raised">
            <Bento.Item columnSpan={12} colorMode="dark" style={{gridRowEnd: 'auto'}} className="position-relative">
              <Bento.Content
                padding={{
                  xsmall: 'normal',
                  medium: 'spacious',
                }}
                horizontalAlign="center"
                className="position-relative z-1"
              >
                <Bento.Heading
                  as="h3"
                  size="3"
                  weight="semibold"
                  className="text-center mt-2 mt-md-0"
                  style={{maxWidth: '660px'}}
                >
                  Ask for assistance right in your terminal
                </Bento.Heading>

                <Link
                  href="https://docs.ghproxy.lvwenzhuo2003.xyz/copilot/using-github-copilot/using-github-copilot-in-the-command-line"
                  size="large"
                  variant="default"
                  {...analyticsEvent({action: 'cli_docs', tag: 'link', context: 'terminal', location: 'features'})}
                >
                  Try Copilot in the CLI
                </Link>
              </Bento.Content>

              <Bento.Visual fillMedia={false} className="lp-Features-terminal-visual">
                <Image
                  src={CopilotTerminalLgImage}
                  alt="Screenshot of GitHub Copilot CLI in a terminal"
                  width="831"
                  height="369"
                  className="lp-Features-terminal-visual-img d-none d-md-block mx-auto"
                />

                <Image
                  src={CopilotTerminalSmImage}
                  alt="Screenshot of GitHub Copilot CLI in a terminal"
                  width="558"
                  height="369"
                  className="lp-Features-terminal-visual-img d-block d-md-none ml-auto"
                />

                <Image
                  src={CopilotTerminalBgImage}
                  alt=""
                  width="1248"
                  height="620"
                  className="lp-Features-terminal-bg"
                  aria-hidden
                />
              </Bento.Visual>
            </Bento.Item>

            <Bento.Item
              columnSpan={{xsmall: 12, medium: 5, large: 5, xlarge: 5}}
              rowSpan={{xsmall: 4, small: 4, medium: 4, xlarge: 5}}
              className="Bento-item"
            >
              <Bento.Content
                horizontalAlign={{xsmall: 'center', large: 'start'}}
                padding={{xsmall: 'normal', xlarge: 'spacious'}}
                leadingVisual={<DeviceMobileIcon />}
                className="lp-Features-mobile"
              >
                <Bento.Heading as="h3" size="4" weight="semibold" className="lp-Features-mobileText">
                  Chat with your AI pair programmer on-the-go
                </Bento.Heading>
              </Bento.Content>
              <Bento.Visual
                padding={{xsmall: 'normal', xlarge: 'spacious'}}
                fillMedia={false}
                horizontalAlign="center"
                verticalAlign="end"
                style={{columnGap: '24px', flexWrap: 'wrap'}}
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.github.android"
                  target="_blank"
                  rel="noreferrer"
                  {...analyticsEvent({
                    action: 'play_store',
                    tag: 'button',
                    context: 'mobile_apps',
                    location: 'features',
                  })}
                >
                  <Image
                    src={GooglePlayImage}
                    alt="Download GitHub on the Google Play Store"
                    width="180"
                    height="53"
                    style={{display: 'block'}}
                  />
                </a>
                <a
                  href="https://apps.apple.com/app/github/id1477376905?ls=1"
                  target="_blank"
                  rel="noreferrer"
                  {...analyticsEvent({
                    action: 'app_store',
                    tag: 'button',
                    context: 'mobile_apps',
                    location: 'features',
                  })}
                >
                  <Image
                    src={AppStoreImage}
                    alt="Download GitHub on the Apple App Store"
                    width="179"
                    height="53"
                    style={{display: 'block'}}
                  />
                </a>
              </Bento.Visual>
            </Bento.Item>

            <Bento.Item
              columnSpan={{xsmall: 12, medium: 7, large: 7, xlarge: 7}}
              rowSpan={{xsmall: 4, small: 3, medium: 4, xlarge: 5}}
            >
              <Bento.Visual position="25% 0%">
                <Image
                  src={CopilotMobileImage}
                  alt="A phone showing GitHub Copilot in GitHub Mobile"
                  width="724"
                  height="560"
                />
              </Bento.Visual>
            </Bento.Item>
          </Bento>
        </Grid.Column>
      </Grid>
    </section>
  )
}

try{ FeaturesSection.displayName ||= 'FeaturesSection' } catch {}