// Firefox bug: Instead of the height, set the width instead
// See https://ghproxy.lvwenzhuo2003.xyz/github/brand-marketing-design/issues/1781#issuecomment-2445889440
import ShopifyLogo from '../../assets/logos/shopify.svg'
import EyLogo from '../../assets/logos/ey.svg'
import FigmaLogo from '../../assets/logos/figma.svg'
import DuolingoLogo from '../../assets/logos/duolingo.svg'
import NewYorkTimesLogo from '../../assets/logos/newyorktimes.svg'
import MercadoLibreLogo from '../../assets/logos/mercado-libre.svg'
import AmericanAirlinesLogo from '../../assets/logos/american-airlines.svg'
import FordLogo from '../../assets/logos/ford.svg'
import MercedesLogo from '../../assets/logos/mercedes.svg'
import SocieteGeneraleLogo from '../../assets/logos/societe-generale.svg'
import VodafoneLogo from '../../assets/logos/vodafone.svg'
import PhilipsLogo from '../../assets/logos/philips.svg'
import SapLogo from '../../assets/logos/sap.svg'
import InfosysLogo from '../../assets/logos/infosys.svg'
import SpotifyLogo from '../../assets/logos/spotify.svg'

export const LOGOS = [
  {
    image: ShopifyLogo,
    alt: 'Shopify',
    width: '148px',
  },
  {
    image: EyLogo,
    alt: 'EY',
    width: '53px',
  },
  {
    image: FigmaLogo,
    alt: 'Figma',
    width: '114px',
  },
  {
    image: DuolingoLogo,
    alt: 'Duolingo',
    width: '147px',
  },
  {
    image: NewYorkTimesLogo,
    alt: 'New York Times',
    width: '250px',
  },
  {
    image: MercadoLibreLogo,
    alt: 'Mercado Libre',
    width: '158px',
  },
  {
    image: AmericanAirlinesLogo,
    alt: 'American Airlines',
    width: '260px',
  },
  {
    image: FordLogo,
    alt: 'Ford',
    width: '128px',
  },
  {
    image: MercedesLogo,
    alt: 'Mercedes Benz',
    width: '220px',
  },
  {
    image: SocieteGeneraleLogo,
    alt: 'Société Générale',
    width: '167px',
  },
  {
    image: VodafoneLogo,
    alt: 'Vodafone',
    width: '168px',
  },
  {
    image: PhilipsLogo,
    alt: 'Philips',
    width: '132px',
  },
  {
    image: SapLogo,
    alt: 'SAP',
    width: '90px',
  },
  {
    image: InfosysLogo,
    alt: 'Infosys',
    width: '100px',
  },
  {
    image: SpotifyLogo,
    alt: 'Spotify',
    width: '150px',
  },
]
